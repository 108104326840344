@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap");
body[data-theme="dark"] .awssld__container figure, body[data-theme="dark"] .awssld__content, body[data-theme="dark"] .awssld__box, body[data-theme="light"] .awssld__container body[data-theme="dark"] figure {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

body[data-theme="dark"] .awssld__controls button, body[data-theme="dark"] .awssld__bullets button, body[data-theme="light"] .awssld__controls body[data-theme="dark"] button, body[data-theme="light"] .awssld__bullets body[data-theme="dark"] button {
  outline-color: 0;
  outline-style: none;
  outline-width: 0; }

body[data-theme="dark"] .awssld {
  --organic-arrow-thickness: 4px;
  --organic-arrow-height: 40px;
  --slider-height-percentage: 60%;
  --loader-bar-color: #656565;
  --loader-bar-height: 6px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.5;
  --control-button-hover-opacity: 0.75;
  --control-button-background: transparent;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 575ms;
  --organic-arrow-color: #8C8C8C;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #8C8C8C;
  --control-bullet-active-color: #8C8C8C;
  --content-background-color: #8C8C8C; }

body[data-theme="dark"] .awssld {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%; }
  body[data-theme="dark"] .awssld__wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%; }
  body[data-theme="dark"] .awssld__container {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: var(--slider-height-percentage); }
    @media all and (max-width: 500px) {
      body[data-theme="dark"] .awssld__container {
        padding-bottom: calc(var(--slider-height-percentage) * 1.25); } }
  body[data-theme="dark"] .awssld__startUp {
    background-color: red;
    height: 100%;
    width: 100%; }
    body[data-theme="dark"] .awssld__startUp > div {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    body[data-theme="dark"] .awssld__startUp img {
      width: 35%;
      height: auto; }
  body[data-theme="dark"] .awssld__content {
    background-color: var(--content-background-color);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
    body[data-theme="dark"] .awssld__content > img,
    body[data-theme="dark"] .awssld__content > video {
      height: 100%;
      top: 0;
      left: 0; }
  body[data-theme="dark"] .awssld__controls button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--control-button-width);
    height: var(--control-button-height);
    position: absolute;
    z-index: 10;
    top: calc(50% - (0.5 * var(--control-button-height)));
    border: none;
    background-color: var(--control-button-background);
    color: #fff;
    cursor: pointer; }
    body[data-theme="dark"] .awssld__controls button .awssld__controls__arrow-left,
    body[data-theme="dark"] .awssld__controls button .awssld__controls__arrow-right {
      opacity: var(--control-button-opacity); }
    body[data-theme="dark"] .awssld__controls button:hover .awssld__controls__arrow-left,
    body[data-theme="dark"] .awssld__controls button:hover .awssld__controls__arrow-right {
      opacity: var(--control-button-opacity-hover); }
  body[data-theme="dark"] .awssld__controls--active .awssld__controls__arrow-left {
    opacity: var(--control-button-opacity-hover);
    transform: translate3d(-100%, 0, 0); }
  body[data-theme="dark"] .awssld__controls--active .awssld__controls__arrow-right {
    opacity: var(--control-button-opacity-hover);
    transform: translate3d(100%, 0, 0); }
  body[data-theme="dark"] .awssld__controls--hidden {
    display: none; }
  @media all and (max-width: 520px) {
    body[data-theme="dark"] .awssld__controls {
      visibility: hidden; } }
  body[data-theme="dark"] .awssld__bar {
    display: block;
    width: 100%;
    height: var(--loader-bar-height);
    background-color: var(--loader-bar-color);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    transition: transform 3000ms var(--transition-bezier);
    transform: translate3d(-100%, 0, 0); }
    body[data-theme="dark"] .awssld__bar--active {
      transform: translate3d(-20%, 0, 0); }
    body[data-theme="dark"] .awssld__bar--end {
      transition-duration: 300ms;
      transform: translate3d(0, 0, 0); }
  body[data-theme="dark"] .awssld__next {
    right: 0; }
  body[data-theme="dark"] .awssld__prev {
    left: 0; }
  body[data-theme="dark"] .awssld__box {
    z-index: 1;
    visibility: hidden; }
  body[data-theme="dark"] .awssld--animated {
    will-change: transform;
    visibility: visible; }
  body[data-theme="dark"] .awssld--animated-mobile {
    will-change: transform;
    transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1); }
  body[data-theme="dark"] .awssld--active {
    visibility: visible;
    z-index: 2;
    transform: translate3d(0, 0, 0); }
  body[data-theme="dark"] .awssld--moveRight, body[data-theme="dark"] .awssld--moveLeft {
    backface-visibility: hidden; }
  body[data-theme="dark"] .awssld--moveRight {
    animation: slideFromRight var(--slider-transition-duration) both var(--transition-bezier); }
  body[data-theme="dark"] .awssld--moveLeft {
    animation: slideFromLeft var(--slider-transition-duration) both var(--transition-bezier); }
  body[data-theme="dark"] .awssld--exit {
    z-index: 0; }
    body[data-theme="dark"] .awssld--exit.awssld--moveLeft {
      animation: slideToLeft var(--slider-transition-duration) both var(--transition-bezier); }
    body[data-theme="dark"] .awssld--exit.awssld--moveRight {
      animation: slideToRight var(--slider-transition-duration) both var(--transition-bezier); }
  body[data-theme="dark"] .awssld--first .awssld__prev {
    visibility: hidden; }
  body[data-theme="dark"] .awssld--last .awssld__next {
    visibility: hidden; }
  body[data-theme="dark"] .awssld--fill-parent {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0; }
    body[data-theme="dark"] .awssld--fill-parent .awssld__container {
      height: 100%;
      padding: 0; }
  body[data-theme="dark"] .awssld__bullets {
    position: absolute;
    bottom: -40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    body[data-theme="dark"] .awssld__bullets button {
      padding: 0;
      display: block;
      width: 16px;
      height: 16px;
      margin: 5px;
      border-radius: 50%;
      background: var(--control-bullet-color);
      text-indent: -9999px;
      overflow: hidden;
      cursor: pointer;
      border: none;
      transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out; }
      body[data-theme="dark"] .awssld__bullets button:hover {
        transform: scale(1.2); }
    body[data-theme="dark"] .awssld__bullets .awssld__bullets--loading {
      transform: scale(1.2); }
    body[data-theme="dark"] .awssld__bullets .awssld__bullets--active {
      transform: scale(1.5);
      background: var(--control-bullet-active-color); }
      body[data-theme="dark"] .awssld__bullets .awssld__bullets--active:hover {
        transform: scale(1.5); }
  body[data-theme="dark"] .awssld__controls__arrow-left, body[data-theme="dark"] .awssld__controls__arrow-right {
    width: 100%;
    height: var(--organic-arrow-height);
    position: relative;
    display: block;
    transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out; }
    body[data-theme="dark"] .awssld__controls__arrow-left:before, body[data-theme="dark"] .awssld__controls__arrow-left:after, body[data-theme="dark"] .awssld__controls__arrow-right:before, body[data-theme="dark"] .awssld__controls__arrow-right:after {
      content: ' ';
      position: absolute;
      right: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2);
      height: 100%;
      border-radius: var(--organic-arrow-border-radius);
      width: var(--organic-arrow-thickness);
      background-color: var(--organic-arrow-color);
      transition: transform 0.15s ease-out, background-color 0.15s ease-out; }
    body[data-theme="dark"] .awssld__controls__arrow-left:before, body[data-theme="dark"] .awssld__controls__arrow-right:before {
      transform-origin: 100% 100% 0;
      top: -50%;
      transform: rotate(-45deg); }
    body[data-theme="dark"] .awssld__controls__arrow-left:after, body[data-theme="dark"] .awssld__controls__arrow-right:after {
      transform-origin: 100% 0% 0;
      top: 50%;
      transform: rotate(45deg); }
  body[data-theme="dark"] .awssld__controls__arrow-right--active {
    transform: translate3d(100%, 0, 0); }
    body[data-theme="dark"] .awssld__controls__arrow-right--active:after {
      transform: rotate(90deg) translate3d(50%, 0, 0) !important; }
    body[data-theme="dark"] .awssld__controls__arrow-right--active:before {
      transform: rotate(-90deg) translate3d(50%, 0, 0) !important; }
  body[data-theme="dark"] .awssld__controls__arrow-left:before, body[data-theme="dark"] .awssld__controls__arrow-left:after {
    right: auto;
    left: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2); }
  body[data-theme="dark"] .awssld__controls__arrow-left:before {
    transform-origin: 0 100% 0;
    top: -50%;
    transform: rotate(45deg); }
  body[data-theme="dark"] .awssld__controls__arrow-left:after {
    transform-origin: 0 0 0;
    top: 50%;
    transform: rotate(-45deg); }
  body[data-theme="dark"] .awssld__controls__arrow-left--active {
    transform: translate3d(-100%, 0, 0); }
    body[data-theme="dark"] .awssld__controls__arrow-left--active:after {
      transform: rotate(-90deg) translate3d(-50%, 0, 0) !important; }
    body[data-theme="dark"] .awssld__controls__arrow-left--active:before {
      transform: rotate(90deg) translate3d(-50%, 0, 0) !important; }
  body[data-theme="dark"] .awssld__controls button:hover .awssld__controls__arrow-left:before {
    opacity: 1;
    transform: rotate(30deg); }
  body[data-theme="dark"] .awssld__controls button:hover .awssld__controls__arrow-left:after {
    opacity: 1;
    transform: rotate(-30deg); }
  body[data-theme="dark"] .awssld__controls button:hover .awssld__controls__arrow-right:before {
    opacity: 1;
    transform: rotate(-30deg); }
  body[data-theme="dark"] .awssld__controls button:hover .awssld__controls__arrow-right:after {
    opacity: 1;
    transform: rotate(30deg); }
  body[data-theme="dark"] .awssld__timer {
    --timer-delay: 2000ms;
    --timer-release: 200ms;
    --timer-height: 4px;
    --timer-background-color: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: var(--timer-height);
    background-color: var(--timer-background-color);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    transform: translateX(-100%); }
    body[data-theme="dark"] .awssld__timer--animated {
      will-change: transform;
      transition: transform var(--timer-delay) linear; }
    body[data-theme="dark"] .awssld__timer--run {
      transform: translateX(0); }
    body[data-theme="dark"] .awssld__timer--fast {
      transition: transform calc(var(--timer-release) / 2) linear;
      transform: translateX(-0.00001px); }
    body[data-theme="dark"] .awssld__timer--end {
      transform: translateX(-0.0001px);
      transition: transform var(--timer-release) linear; }
    body[data-theme="dark"] .awssld__timer--hidden {
      display: none; }

@keyframes slideFromLeft {
  from {
    transform: translate3d(-100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes slideFromRight {
  from {
    transform: translate3d(100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes slideToLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideToRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(100%, 0, 0); } }

body[data-theme="dark"] .slider-tab {
  background-color: #8C8C8C; }

body[data-theme="dark"] .title-styles {
  color: white; }

body[data-theme="dark"] .header-icon {
  color: #3C342C;
  opacity: 0.8; }

body[data-theme="dark"] .slider-image {
  border: 5px solid #8C8C8C; }

body[data-theme="dark"] .language {
  background-color: #494949; }

body[data-theme="dark"] .link-href {
  color: white; }

body[data-theme="dark"] .project-date {
  background-color: #918e97;
  color: white; }

body[data-theme="dark"] header {
  background-color: #494949; }
  body[data-theme="dark"] header h1 {
    color: white; }

body[data-theme="dark"] #about {
  background-color: #7f7f7f; }
  body[data-theme="dark"] #about h1 span {
    color: white; }
  body[data-theme="dark"] #about .polaroid span {
    background: #6d6d6d; }
  body[data-theme="dark"] #about .card {
    background: #6d6d6d;
    color: white; }

body[data-theme="dark"] #portfolio {
  background: #5b5b5b; }
  body[data-theme="dark"] #portfolio .section-title {
    color: white !important; }
  body[data-theme="dark"] #portfolio .foto div {
    background: #6d6d6d; }
  body[data-theme="dark"] #portfolio .project-title-settings {
    color: white; }

body[data-theme="dark"] #resume {
  filter: brightness(80%);
  background: #5b5b5b; }
  body[data-theme="dark"] #resume .section-title {
    color: white !important; }
  body[data-theme="dark"] #resume .experience-badge {
    background: #919191 !important;
    color: white; }
  body[data-theme="dark"] #resume .main-badge {
    background: #919191 !important; }
  body[data-theme="dark"] #resume .vertical-timeline-element-date {
    color: white; }
  body[data-theme="dark"] #resume .vertical-timeline-element-icon {
    background: #919191 !important; }
  @media only screen and (max-width: 1169px) {
    body[data-theme="dark"] #resume .vertical-timeline-element-date {
      color: black; } }

body[data-theme="dark"] .modal-inside .modal-content {
  background: #5b5b5b;
  color: white; }

body[data-theme="dark"] .close-icon {
  color: white; }

body[data-theme="dark"] .awssld__container body[data-theme="light"] figure, body[data-theme="light"] .awssld__container figure, body[data-theme="light"] .awssld__content, body[data-theme="light"] .awssld__box {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

body[data-theme="dark"] .awssld__controls body[data-theme="light"] button, body[data-theme="dark"] .awssld__bullets body[data-theme="light"] button, body[data-theme="light"] .awssld__controls button, body[data-theme="light"] .awssld__bullets button {
  outline-color: 0;
  outline-style: none;
  outline-width: 0; }

body[data-theme="light"] .awssld {
  --organic-arrow-thickness: 4px;
  --organic-arrow-height: 40px;
  --slider-height-percentage: 60%;
  --loader-bar-color: #cc0000;
  --loader-bar-height: 6px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.5;
  --control-button-hover-opacity: 0.75;
  --control-button-background: transparent;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 575ms;
  --organic-arrow-color: #cc0000;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #EBEBEB;
  --control-bullet-active-color: #5a63df;
  --content-background-color: #EBEBEB; }

body[data-theme="light"] .awssld {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%; }
  body[data-theme="light"] .awssld__wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%; }
  body[data-theme="light"] .awssld__container {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: var(--slider-height-percentage); }
    @media all and (max-width: 500px) {
      body[data-theme="light"] .awssld__container {
        padding-bottom: calc(var(--slider-height-percentage) * 1.25); } }
  body[data-theme="light"] .awssld__startUp {
    background-color: red;
    height: 100%;
    width: 100%; }
    body[data-theme="light"] .awssld__startUp > div {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    body[data-theme="light"] .awssld__startUp img {
      width: 35%;
      height: auto; }
  body[data-theme="light"] .awssld__content {
    background-color: var(--content-background-color);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
    body[data-theme="light"] .awssld__content > img,
    body[data-theme="light"] .awssld__content > video {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0; }
  body[data-theme="light"] .awssld__controls button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--control-button-width);
    height: var(--control-button-height);
    position: absolute;
    z-index: 10;
    top: calc(50% - (0.5 * var(--control-button-height)));
    border: none;
    background-color: var(--control-button-background);
    color: #fff;
    cursor: pointer; }
    body[data-theme="light"] .awssld__controls button .awssld__controls__arrow-left,
    body[data-theme="light"] .awssld__controls button .awssld__controls__arrow-right {
      opacity: var(--control-button-opacity); }
    body[data-theme="light"] .awssld__controls button:hover .awssld__controls__arrow-left,
    body[data-theme="light"] .awssld__controls button:hover .awssld__controls__arrow-right {
      opacity: var(--control-button-opacity-hover); }
  body[data-theme="light"] .awssld__controls--active .awssld__controls__arrow-left {
    opacity: var(--control-button-opacity-hover);
    transform: translate3d(-100%, 0, 0); }
  body[data-theme="light"] .awssld__controls--active .awssld__controls__arrow-right {
    opacity: var(--control-button-opacity-hover);
    transform: translate3d(100%, 0, 0); }
  body[data-theme="light"] .awssld__controls--hidden {
    display: none; }
  @media all and (max-width: 520px) {
    body[data-theme="light"] .awssld__controls {
      visibility: hidden; } }
  body[data-theme="light"] .awssld__bar {
    display: block;
    width: 100%;
    height: var(--loader-bar-height);
    background-color: var(--loader-bar-color);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    transition: transform 3000ms var(--transition-bezier);
    transform: translate3d(-100%, 0, 0); }
    body[data-theme="light"] .awssld__bar--active {
      transform: translate3d(-20%, 0, 0); }
    body[data-theme="light"] .awssld__bar--end {
      transition-duration: 300ms;
      transform: translate3d(0, 0, 0); }
  body[data-theme="light"] .awssld__next {
    right: 0; }
  body[data-theme="light"] .awssld__prev {
    left: 0; }
  body[data-theme="light"] .awssld__box {
    z-index: 1;
    visibility: hidden; }
  body[data-theme="light"] .awssld--animated {
    will-change: transform;
    visibility: visible; }
  body[data-theme="light"] .awssld--animated-mobile {
    will-change: transform;
    transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1); }
  body[data-theme="light"] .awssld--active {
    visibility: visible;
    z-index: 2;
    transform: translate3d(0, 0, 0); }
  body[data-theme="light"] .awssld--moveRight, body[data-theme="light"] .awssld--moveLeft {
    backface-visibility: hidden; }
  body[data-theme="light"] .awssld--moveRight {
    animation: slideFromRight var(--slider-transition-duration) both var(--transition-bezier); }
  body[data-theme="light"] .awssld--moveLeft {
    animation: slideFromLeft var(--slider-transition-duration) both var(--transition-bezier); }
  body[data-theme="light"] .awssld--exit {
    z-index: 0; }
    body[data-theme="light"] .awssld--exit.awssld--moveLeft {
      animation: slideToLeft var(--slider-transition-duration) both var(--transition-bezier); }
    body[data-theme="light"] .awssld--exit.awssld--moveRight {
      animation: slideToRight var(--slider-transition-duration) both var(--transition-bezier); }
  body[data-theme="light"] .awssld--first .awssld__prev {
    visibility: hidden; }
  body[data-theme="light"] .awssld--last .awssld__next {
    visibility: hidden; }
  body[data-theme="light"] .awssld--fill-parent {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0; }
    body[data-theme="light"] .awssld--fill-parent .awssld__container {
      height: 100%;
      padding: 0; }
  body[data-theme="light"] .awssld__bullets {
    position: absolute;
    bottom: -40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    body[data-theme="light"] .awssld__bullets button {
      padding: 0;
      display: block;
      width: 16px;
      height: 16px;
      margin: 5px;
      border-radius: 50%;
      background: var(--control-bullet-color);
      text-indent: -9999px;
      overflow: hidden;
      cursor: pointer;
      border: none;
      transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out; }
      body[data-theme="light"] .awssld__bullets button:hover {
        transform: scale(1.2); }
    body[data-theme="light"] .awssld__bullets .awssld__bullets--loading {
      transform: scale(1.2); }
    body[data-theme="light"] .awssld__bullets .awssld__bullets--active {
      transform: scale(1.5);
      background: var(--control-bullet-active-color); }
      body[data-theme="light"] .awssld__bullets .awssld__bullets--active:hover {
        transform: scale(1.5); }
  body[data-theme="light"] .awssld__controls__arrow-left, body[data-theme="light"] .awssld__controls__arrow-right {
    width: 100%;
    height: var(--organic-arrow-height);
    position: relative;
    display: block;
    transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out; }
    body[data-theme="light"] .awssld__controls__arrow-left:before, body[data-theme="light"] .awssld__controls__arrow-left:after, body[data-theme="light"] .awssld__controls__arrow-right:before, body[data-theme="light"] .awssld__controls__arrow-right:after {
      content: ' ';
      position: absolute;
      right: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2);
      height: 100%;
      border-radius: var(--organic-arrow-border-radius);
      width: var(--organic-arrow-thickness);
      background-color: var(--organic-arrow-color);
      transition: transform 0.15s ease-out, background-color 0.15s ease-out; }
    body[data-theme="light"] .awssld__controls__arrow-left:before, body[data-theme="light"] .awssld__controls__arrow-right:before {
      transform-origin: 100% 100% 0;
      top: -50%;
      transform: rotate(-45deg); }
    body[data-theme="light"] .awssld__controls__arrow-left:after, body[data-theme="light"] .awssld__controls__arrow-right:after {
      transform-origin: 100% 0% 0;
      top: 50%;
      transform: rotate(45deg); }
  body[data-theme="light"] .awssld__controls__arrow-right--active {
    transform: translate3d(100%, 0, 0); }
    body[data-theme="light"] .awssld__controls__arrow-right--active:after {
      transform: rotate(90deg) translate3d(50%, 0, 0) !important; }
    body[data-theme="light"] .awssld__controls__arrow-right--active:before {
      transform: rotate(-90deg) translate3d(50%, 0, 0) !important; }
  body[data-theme="light"] .awssld__controls__arrow-left:before, body[data-theme="light"] .awssld__controls__arrow-left:after {
    right: auto;
    left: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2); }
  body[data-theme="light"] .awssld__controls__arrow-left:before {
    transform-origin: 0 100% 0;
    top: -50%;
    transform: rotate(45deg); }
  body[data-theme="light"] .awssld__controls__arrow-left:after {
    transform-origin: 0 0 0;
    top: 50%;
    transform: rotate(-45deg); }
  body[data-theme="light"] .awssld__controls__arrow-left--active {
    transform: translate3d(-100%, 0, 0); }
    body[data-theme="light"] .awssld__controls__arrow-left--active:after {
      transform: rotate(-90deg) translate3d(-50%, 0, 0) !important; }
    body[data-theme="light"] .awssld__controls__arrow-left--active:before {
      transform: rotate(90deg) translate3d(-50%, 0, 0) !important; }
  body[data-theme="light"] .awssld__controls button:hover .awssld__controls__arrow-left:before {
    opacity: 1;
    transform: rotate(30deg); }
  body[data-theme="light"] .awssld__controls button:hover .awssld__controls__arrow-left:after {
    opacity: 1;
    transform: rotate(-30deg); }
  body[data-theme="light"] .awssld__controls button:hover .awssld__controls__arrow-right:before {
    opacity: 1;
    transform: rotate(-30deg); }
  body[data-theme="light"] .awssld__controls button:hover .awssld__controls__arrow-right:after {
    opacity: 1;
    transform: rotate(30deg); }
  body[data-theme="light"] .awssld__timer {
    --timer-delay: 2000ms;
    --timer-release: 200ms;
    --timer-height: 4px;
    --timer-background-color: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: var(--timer-height);
    background-color: var(--timer-background-color);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    transform: translateX(-100%); }
    body[data-theme="light"] .awssld__timer--animated {
      will-change: transform;
      transition: transform var(--timer-delay) linear; }
    body[data-theme="light"] .awssld__timer--run {
      transform: translateX(0); }
    body[data-theme="light"] .awssld__timer--fast {
      transition: transform calc(var(--timer-release) / 2) linear;
      transform: translateX(-0.00001px); }
    body[data-theme="light"] .awssld__timer--end {
      transform: translateX(-0.0001px);
      transition: transform var(--timer-release) linear; }
    body[data-theme="light"] .awssld__timer--hidden {
      display: none; }

@keyframes slideFromLeft {
  from {
    transform: translate3d(-100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes slideFromRight {
  from {
    transform: translate3d(100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes slideToLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideToRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(100%, 0, 0); } }

body[data-theme="light"] .slider-tab {
  background-color: #EBEBEB; }

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0; }

.aligner {
  display: flex;
  align-items: center;
  justify-content: center; }

.title-styles {
  font-family: 'Raleway', sans-serif;
  font-size: 250%; }

.header-icon {
  height: 150px;
  width: 150px; }

header {
  position: relative;
  height: 520px;
  min-height: 450px;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  text-align: center;
  overflow: hidden;
  background-color: #EBEBEB;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 0; }

.language-icon {
  font-size: 50px;
  cursor: pointer; }

.project-date {
  font-size: 16px;
  font-family: "Tahoma", Geneva, Verdana, sans-serif;
  padding: 8px 15px 2px 15px;
  position: relative;
  z-index: -500;
  border-top: 5px solid #696969;
  border-radius: 0 0 5px 5px;
  background-color: #696969;
  color: white; }

.skills-tile {
  background-color: rgba(0, 0, 0, 0.45);
  padding: 10px 10px 5px 10px;
  width: 100px;
  margin: 5px 0 5px 0;
  border-radius: 8px; }

.language {
  font-size: 25px;
  background-color: #EBEBEB;
  padding-bottom: 10px;
  padding-top: 80px; }

header h1 {
  font-size: 400%;
  text-align: center;
  font-weight: 600 !important;
  color: #353239;
  font-family: 'Verdana', 'Arial Narrow', Arial, sans-serif;
  margin: 0 auto 18px auto;
  width: 100%; }

.slider-image {
  border: 5px solid #EBEBEB; }

.slider-tab {
  background-color: #4d4c4c;
  height: 25px; }

.slider-iconfiy {
  margin-top: 10px; }

.styles_typicalWrapper__1_Uvh::after {
  cursor: none !important;
  display: none; }

#about {
  background: #c9c5c5;
  overflow: hidden; }

#about h1 {
  padding-top: 5%;
  font: 20px/24px 'Verdana', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: black;
  text-align: center; }

.foto img {
  display: block;
  width: 100%; }

.foto div {
  background: #EBEBEB;
  display: inline-block;
  margin: 0 auto 5% auto;
  padding: 10px 10px 5px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 5px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 2px rgba(0, 0, 0, 0.3);
  -webkit-transition: all .10s linear;
  -moz-transition: all .10s linear;
  transition: all .10s linear;
  z-index: 0;
  position: relative; }

.foto div:after {
  color: #333;
  font-size: 25px;
  content: attr(title);
  position: relative;
  top: 15px; }

.foto div:hover {
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  transform: scale(1.01);
  z-index: 10;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.7); }

.project-title-settings {
  margin-top: 5%;
  font: 18px/24px 'Verdana', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  color: black;
  text-align: center; }

.polaroid img {
  display: block;
  max-width: 500px; }

.polaroid span {
  background: #EBEBEB;
  display: inline-block;
  margin: 75px 75px 75px;
  padding: 15px 15px 15px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.3);
  -webkit-transition: all .20s linear;
  -moz-transition: all .20s linear;
  transition: all .20s linear;
  z-index: 0; }

.link-href {
  color: black; }

.wave {
  font-size: 160%; }

.font-trebuchet {
  font-family: 'Trebuchet MS', sans-serif; }

#preview {
  width: 500px;
  background-color: #ebeaf5;
  padding: 15px;
  position: relative;
  margin-bottom: 15%; }

.center {
  display: flex;
  justify-content: center;
  align-items: center; }

#resume {
  background: #c9c5c5; }

.experience-icon {
  font-size: 300%;
  margin-top: 25%;
  text-align: center; }

.main-badge {
  font-size: 12px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #cc0000 !important;
  color: white;
  font-weight: lighter !important;
  font-family: 'Verdana',sans-serif; }

.experience-badge {
  font-size: 12px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #f9f5e9 !important;
  color: black;
  font-weight: lighter !important;
  font-family: 'Verdana',sans-serif; }

.vertical-timeline-element-date {
  padding: 50 !important;
  margin: 0 !important;
  font-size: 20px !important;
  color: #110202; }

@media only screen and (max-width: 1170px) {
  .experience-icon {
    font-size: 300%;
    margin-top: 27%;
    text-align: center; } }

.modal-inside .modal-content {
  background: white; }

.bars {
  width: 95%;
  float: left;
  padding: 0;
  text-align: left; }

.bars .skills {
  margin-top: 36px;
  list-style: none; }

.bars li {
  position: relative;
  margin-bottom: 60px;
  background: #831313;
  height: 42px;
  border-radius: 3px; }

.bars li em {
  font: 15px 'opensans-bold', sans-serif;
  color: #313131;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  position: relative;
  top: -36px; }

.bar-expand {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding-right: 24px;
  background: #313131;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  border-radius: 3px 0 0 3px; }

.modal-close {
  text-align: right;
  padding: 10px 15px 10px 15px;
  cursor: pointer; }

.close-icon {
  color: black;
  font-weight: lighter !important; }

.modal-description {
  text-align: justify;
  padding: 5px 5px 0 5px;
  margin-bottom: 20px;
  font-size: 12px; }

.awssld__next {
  outline: none !important; }

.awssld__prev {
  outline: none !important; }

.loader-bar-color {
  color: black !important; }

#portfolio {
  background: #EBEBEB;
  padding-bottom: 5%; }

.portfolio-item {
  max-width: 100%;
  margin-bottom: 15px;
  text-align: center; }

.portfolio .portfolio-item .portfolio-item-caption {
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
  background-color: rgba(51, 51, 51, 0.9); }

.portfolio .portfolio-item .portfolio-item-caption:hover {
  opacity: 1; }

.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content {
  font-size: 1.5rem; }

@media (min-width: 576px) {
  .portfolio .closeButtonResponsive {
    display: block; }
  .portfolio .portfolio-item {
    margin-bottom: 30px; } }

#skills {
  color: black;
  background: #cc0000;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 10%; }

.section-title {
  padding-top: 5%;
  padding-bottom: 5%;
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  text-align: center; }

.skill-icon {
  font-size: 180%;
  color: white;
  text-align: center;
  position: relative; }

footer {
  background: #1F1F1F;
  min-height: 100px;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  color: white;
  position: relative;
  text-align: center; }

footer a,
footer a:visited {
  color: #fff; }

footer a:hover,
footer a:focus {
  color: #fff; }

.social-links {
  margin-top: 5px;
  font-size: 70px; }

body {
  background: #2d2d37; }

a {
  color: white;
  text-decoration: none; }

.arrow {
  transform: scale(5);
  text-align: center;
  margin: 1% 0; }

.bounce {
  -moz-animation: bounce 5s infinite;
  -webkit-animation: bounce 5s infinite;
  animation: bounce 5s infinite; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

.container {
  position: relative;
  text-align: center;
  width: 10px;
  height: 24px; }

.chevron {
  position: absolute;
  text-align: center;
  margin-left: calc(50% - 42px);
  width: 46px;
  height: 10px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite; }

.chevron:first-child {
  animation: move 3s ease-out 1s infinite; }

.chevron:nth-child(2) {
  animation: move 3s ease-out 0.3s infinite; }

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  text-align: center;
  top: 0;
  height: 100%;
  width: 51%;
  background: #1E90FF; }

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg); }

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg); }

@keyframes move {
  25% {
    opacity: 1; }
  33% {
    opacity: 1;
    transform: translateY(30px); }
  67% {
    opacity: 1;
    transform: translateY(40px); }
  100% {
    opacity: 0;
    transform: translateY(70px) scale3d(0.3, 0.3, 0.3); } }

.text {
  display: block;
  margin-top: 75px;
  margin-left: -15px;
  font-family: "opensans-bold", Tahoma, sans-serif;
  font-size: 15px;
  color: #1E90FF;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: .25;
  animation: pulse 2s linear alternate infinite; }

@keyframes pulse {
  to {
    opacity: 1; } }
