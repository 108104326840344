@import '../src/scss/themes/theme-dark.scss';
@import '../src/scss/themes/theme-light.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap');

html {
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
}

.aligner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.title-styles {
	font-family: 'Raleway', sans-serif;
	font-size: 250%;
}

.header-icon {
	height: 150px;
	width: 150px;
}

header {
	position: relative;
	height: 520px;
	min-height: 450px;
	width: 100%;
	background-size: cover;
	-webkit-background-size: cover;
	text-align: center;
	overflow: hidden;
	background-color: #EBEBEB ;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding-top: 150px;
	padding-bottom: 0;
}

.language-icon {
	// margin-left:calc(50% + 2500);
	font-size: 50px;
	cursor: pointer;
}

.project-date {
	font-size: 16px;
	font-family: "Tahoma", Geneva, Verdana, sans-serif;
	padding: 8px 15px 2px 15px;
	position: relative;
	z-index: -500;
	border-top: 5px solid #696969;
	border-radius: 0 0 5px 5px;
	background-color: #696969;
	color: white;
}

.skills-tile {
	background-color: transparentize(#000000, 0.55);
	padding: 10px 10px 5px 10px;
	width: 100px;
	margin: 5px 0 5px 0;
	border-radius: 8px;
}

.language {
	font-size: 25px;
	background-color: #EBEBEB;
	padding-bottom: 10px;
	padding-top: 80px;
}

header h1 {
	font-size: 400%;
	text-align: center;
	font-weight: 600 !important;
	color: #353239;
	font-family: 'Verdana', 'Arial Narrow', Arial, sans-serif;
	margin: 0 auto 18px auto;
	width: 100%;
}


.slider-image {
	border: 5px solid #EBEBEB;
}

.slider-tab {
	background-color: #4d4c4c;
	height: 25px;
}

.slider-iconfiy {
	margin-top: 10px;
}

.styles_typicalWrapper__1_Uvh::after {
	cursor: none !important;
	display: none;
}

#about {
	background: rgb(201, 197, 197);
	overflow: hidden;
}

#about h1 {
	padding-top: 5%;
	font: 20px/24px 'Verdana', sans-serif;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: black;
	text-align: center;
}

.foto img {
	display: block;
	width: 100%;
}

.foto div {
	background: #EBEBEB;
	display: inline-block;
	margin: 0 auto 5% auto;
	padding: 10px 10px 5px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 5px 1px rgba(0, 0, 0, .3);
	box-shadow: 0 5px 2px rgba(0, 0, 0, .3);
	-webkit-transition: all .10s linear;
	-moz-transition: all .10s linear;
	transition: all .10s linear;
	z-index: 0;
	position: relative;
}

.foto div:after {
	color: #333;
	font-size: 25px;
	content: attr(title);
	position: relative;
	top: 15px;
}

.foto div:hover {
	-webkit-transform: scale(1.01);
	-moz-transform: scale(1.01);
	transform: scale(1.01);
	z-index: 10;
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
	-moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
	box-shadow: 0 7px 7px rgba(0, 0, 0, .7);
}

.project-title-settings {
	margin-top: 5%;
	font: 18px/24px 'Verdana', sans-serif;
	text-transform: uppercase;
	letter-spacing: 0.1px;
	color: black;
	text-align: center;
}

.polaroid img {
	display: block;
	max-width: 500px;
}

.polaroid span {
	background: #EBEBEB;
	display: inline-block;
	margin: 75px 75px 75px;
	padding: 15px 15px 15px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 0px 0px rgba(0, 0, 0, .3);
	box-shadow: 0 0px 0px rgba(0, 0, 0, .3);
	-webkit-transition: all .20s linear;
	-moz-transition: all .20s linear;
	transition: all .20s linear;
	z-index: 0;
	// position: relative;
}

.link-href {
	color: black;
}

.wave {
	font-size: 160%;
}

.font-trebuchet {
	font-family: 'Trebuchet MS', sans-serif;
}

#preview {
	width: 500px;
	background-color: #ebeaf5;
	padding: 15px;
	position: relative;
	margin-bottom: 15%;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

#resume {
	background: rgb(201, 197, 197);
}
//#c4c2bc
.experience-icon {
	font-size: 300%;
	margin-top: 25%;
	text-align: center;
}

.main-badge {
	font-size: 12px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #cc0000 !important;
	color: white;
	font-weight: lighter !important;
	font-family: 'Verdana',sans-serif;
}

.experience-badge {
	font-size: 12px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #f9f5e9 !important;
	color: black;
	font-weight: lighter !important;
	font-family: 'Verdana',sans-serif;
}

.vertical-timeline-element-date {
	padding: 50 !important;
	margin: 0 !important;
	font-size: 20px !important;
	color:rgb(17, 2, 2)
}

@media only screen and (max-width: 1170px) {
	.experience-icon {
		font-size: 300%;
		margin-top: 27%;
		text-align: center;
	}
}

.modal-inside .modal-content {
	background: white;
}

.bars {
	width: 95%;
	float: left;
	padding: 0;
	text-align: left;
}

.bars .skills {
	margin-top: 36px;
	list-style: none;
}

.bars li {
	position: relative;
	margin-bottom: 60px;
	background: rgb(131, 19, 19);
	height: 42px;
	border-radius: 3px;
}

.bars li em {
	font: 15px 'opensans-bold', sans-serif;
	color: #313131;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: normal;
	position: relative;
	top: -36px;
}

.bar-expand {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	padding-right: 24px;
	background: #313131;
	display: inline-block;
	height: 42px;
	line-height: 42px;
	border-radius: 3px 0 0 3px;
}

.modal-close {
	text-align: right;
	padding: 10px 15px 10px 15px;
	cursor: pointer;
}

.close-icon {
	color: black;
	font-weight: lighter !important;
}

.modal-description {
	text-align: justify;
	padding: 5px 5px 0 5px;
	margin-bottom: 20px;
	font-size: 12px;
}

.awssld__next {
	outline: none !important;
}

.awssld__prev {
	outline: none !important;
}

.loader-bar-color {
	color: black !important;
}

#portfolio {
	background: #EBEBEB;
	padding-bottom: 5%;
}

.portfolio-item {
	max-width: 100%;
	margin-bottom: 15px;
	text-align: center;
}

.portfolio .portfolio-item .portfolio-item-caption {
	-webkit-transition: all ease 0.5s;
	-moz-transition: all ease 0.5s;
	transition: all ease 0.5s;
	opacity: 0;
	background-color: rgba(51, 51, 51, 0.9);
}

.portfolio .portfolio-item .portfolio-item-caption:hover {
	opacity: 1;
}

.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content {
	font-size: 1.5rem;
}

@media (min-width: 576px) {
	.portfolio .closeButtonResponsive {
		display: block;
	}
	.portfolio .portfolio-item {
		margin-bottom: 30px;
	}
}

#skills {
	color:rgba(0, 0, 0, 7);
	background: #cc0000;
	min-height: 200px;
	width: 100%;
	overflow: hidden;
	padding-bottom: 10%;
}
//used to be 1F1F1F

.section-title {
	padding-top: 5%;
	padding-bottom: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: #fff;
	text-align: center;
}

.skill-icon {
	font-size: 180%;
	color: white;
	text-align: center;
	position: relative;
}

footer {
	background: #1F1F1F;
	min-height: 100px;
	width: 100%;
	overflow: hidden;
	font-size: 14px;
	color: white;
	position: relative;
	text-align: center;
}

footer a,
footer a:visited {
	color: #fff;
}

footer a:hover,
footer a:focus {
	color: #fff;
}

.social-links {
	margin-top: 5px;
	font-size: 70px;
}



body { background: #2d2d37;}
a { color: white; text-decoration: none; }


.arrow {
	transform:scale(5);
  text-align: center;
  margin: 1% 0;
}
.bounce {
  -moz-animation: bounce 5s infinite;
  -webkit-animation: bounce 5s infinite;
  animation: bounce 5s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
//   100%{  transform:scale(50);
// 	}

}

.container {
	position: relative;
	text-align: center;
	width: 10px;
	height: 24px;
  }
  
  .chevron {
	position: absolute;
	text-align: center;
	margin-left:calc(50% - 42px);
	width: 46px;
	height: 10px;
	opacity: 0;
	transform: scale3d(0.5, 0.5, 0.5);
	animation: move 3s ease-out infinite;
  }
  
  .chevron:first-child {
	animation: move 3s ease-out 1s infinite;
  }
  
  .chevron:nth-child(2) {
	animation: move 3s ease-out 0.3s infinite;
  }
  
  .chevron:before,
  .chevron:after {
	content: ' ';
	position: absolute;
	text-align: center;
	top: 0;
	height: 100%;
	width: 51%;
	background: #1E90FF;
  }
  
  .chevron:before {
	left: 0;
	transform: skew(0deg, 30deg);
  }
  
  .chevron:after {
	right: 0;
	width: 50%;
	transform: skew(0deg, -30deg);
  }
  
  @keyframes move {
	25% {
	  opacity: 1;
  
	}
	33% {
	  opacity: 1;
	  transform: translateY(30px);
	}
	67% {
	  opacity: 1;
	  transform: translateY(40px);
	}
	100% {
	  opacity: 0;
	  transform: translateY(70px) scale3d(0.3, 0.3, 0.3);
	}
  }
  
  .text {
	display: block;
	margin-top: 75px;
	margin-left: -15px;
	font-family: "opensans-bold", Tahoma, sans-serif;
	font-size: 15px;
	color: 	#1E90FF;
	text-transform: uppercase;
	white-space: nowrap;
	opacity: .25;
	animation: pulse 2s linear alternate infinite;
  }
  
  @keyframes pulse {
	to {
	  opacity: 1;
	}
  }